// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-container {
    box-shadow: 0 0 3px #ccc;
    border-radius: 15px;
    background-color: #ffffff;
    margin: 10px 25px;
    min-height: 150px;
    padding: 10px;
}

.section-container h2 {
    margin: 20px 12px 24px 12px;
    border-bottom: 3px solid black;
    padding-bottom: 4px;
}

.section-container p, ul {
    margin-left: 24px;
}

.section-container .indented {
    margin-left: 48px;
    margin-right: 24px;
}

.section-container .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.section-container button {
    border-radius: 8px;
    border: 1px solid grey;
    padding: 4px 8px;
}

.section-container button:hover {
    filter: brightness(85%);
}

.section-container button:active {
    filter: brightness(80%);
}

.researchQuestion h3 {
    margin-left: 32px;
    margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/DRE-Research/dre-rq.css"],"names":[],"mappings":"AAAA;IAGI,wBAAwB;IACxB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".section-container {\n    -moz-box-shadow: 0 0 3px #ccc;\n    -webkit-box-shadow: 0 0 3px #ccc;\n    box-shadow: 0 0 3px #ccc;\n    border-radius: 15px;\n    background-color: #ffffff;\n    margin: 10px 25px;\n    min-height: 150px;\n    padding: 10px;\n}\n\n.section-container h2 {\n    margin: 20px 12px 24px 12px;\n    border-bottom: 3px solid black;\n    padding-bottom: 4px;\n}\n\n.section-container p, ul {\n    margin-left: 24px;\n}\n\n.section-container .indented {\n    margin-left: 48px;\n    margin-right: 24px;\n}\n\n.section-container .buttons {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.section-container button {\n    border-radius: 8px;\n    border: 1px solid grey;\n    padding: 4px 8px;\n}\n\n.section-container button:hover {\n    filter: brightness(85%);\n}\n\n.section-container button:active {\n    filter: brightness(80%);\n}\n\n.researchQuestion h3 {\n    margin-left: 32px;\n    margin-top: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
