// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    background-color: #F8F8F8;
}

/* .charts-container {
    padding-bottom: 15px;
} */

.chart-home-container {
    box-shadow: 0 0 3px #ccc;
    border-radius: 15px;
    background-color: #ffffff;
    margin: 10px 25px;
    min-height: 150px;
    padding-bottom: 10px;
}

.chart-header {
    display: flex;
    padding: 5px 0 0 20px;
    width: 95%;
}

.chart-header-label {
    margin-top: 9px;
}

.home-navigation-container {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 0 0;
}

.evaluation-selector-container {
    margin-left: 35px;
    display: flex;
}

.evaluation-selector-label {
    margin-top: 7px;
    margin-right: 10px;
}

.evaluation-selector-holder {
    width: 250px;
}

.flex-chart-center svg {
    overflow: visible !important;
}

.center-text {
    width: 100%;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/css/home.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;;GAEG;;AAEH;IAGI,wBAAwB;IACxB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".home-container {\n    background-color: #F8F8F8;\n}\n\n/* .charts-container {\n    padding-bottom: 15px;\n} */\n\n.chart-home-container {\n    -moz-box-shadow: 0 0 3px #ccc;\n    -webkit-box-shadow: 0 0 3px #ccc;\n    box-shadow: 0 0 3px #ccc;\n    border-radius: 15px;\n    background-color: #ffffff;\n    margin: 10px 25px;\n    min-height: 150px;\n    padding-bottom: 10px;\n}\n\n.chart-header {\n    display: flex;\n    padding: 5px 0 0 20px;\n    width: 95%;\n}\n\n.chart-header-label {\n    margin-top: 9px;\n}\n\n.home-navigation-container {\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 25px 0 0;\n}\n\n.evaluation-selector-container {\n    margin-left: 35px;\n    display: flex;\n}\n\n.evaluation-selector-label {\n    margin-top: 7px;\n    margin-right: 10px;\n}\n\n.evaluation-selector-holder {\n    width: 250px;\n}\n\n.flex-chart-center svg {\n    overflow: visible !important;\n}\n\n.center-text {\n    width: 100%;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
