// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableContainer {
  max-height: 70vh;
}

/* TableHead */
.TableHead {
  background-color: #f4f4f4;
}

.text-instructions p {
  margin: 20px 48px 10px 48px;
}

.text-instructions ul {
  margin-left: 48px;
}

.text-instructions h4 {
  font-size: 18px;
  margin-left: 48px;
}

.center-text {
  margin: auto auto 20px auto !important;
  font-weight: 500;
  text-align: center;
}

.text-instructions button {
  margin: auto;
  display: block;
  padding: 12px 50px;
  border-radius: 8px;
  font-size: 18px;
  color: #b15e2f !important;
  border: 1px solid #b15e2f;
  background-color: white;
  box-shadow: 1px 1px 1px;
}

.text-instructions button:hover {
  filter: brightness(97%);
  box-shadow: 1px 2px 2px;
}

.text-instructions button:active {
  filter: brightness(94%);
  box-shadow: 1px 3px 3px;
}

.text-instructions h2 {
  background-color: rgb(89, 38, 16);
  color: white;
  text-align: center;
  padding: 24px;
  font-variant: small-caps;
  font-weight: 300;
  font-size: 40px;
}`, "",{"version":3,"sources":["webpack://./src/css/scenario-page.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA,cAAc;AACd;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,sCAAsC;EACtC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,yBAAyB;EACzB,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,iCAAiC;EACjC,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,wBAAwB;EACxB,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".TableContainer {\n  max-height: 70vh;\n}\n\n/* TableHead */\n.TableHead {\n  background-color: #f4f4f4;\n}\n\n.text-instructions p {\n  margin: 20px 48px 10px 48px;\n}\n\n.text-instructions ul {\n  margin-left: 48px;\n}\n\n.text-instructions h4 {\n  font-size: 18px;\n  margin-left: 48px;\n}\n\n.center-text {\n  margin: auto auto 20px auto !important;\n  font-weight: 500;\n  text-align: center;\n}\n\n.text-instructions button {\n  margin: auto;\n  display: block;\n  padding: 12px 50px;\n  border-radius: 8px;\n  font-size: 18px;\n  color: #b15e2f !important;\n  border: 1px solid #b15e2f;\n  background-color: white;\n  box-shadow: 1px 1px 1px;\n}\n\n.text-instructions button:hover {\n  filter: brightness(97%);\n  box-shadow: 1px 2px 2px;\n}\n\n.text-instructions button:active {\n  filter: brightness(94%);\n  box-shadow: 1px 3px 3px;\n}\n\n.text-instructions h2 {\n  background-color: rgb(89, 38, 16);\n  color: white;\n  text-align: center;\n  padding: 24px;\n  font-variant: small-caps;\n  font-weight: 300;\n  font-size: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
