// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-page {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .admin-header {
    color: #333;
    border-bottom: 2px solid rgba(89, 38, 16, 1);
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .card {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .card:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
  }
  
  .loading, .error {
    text-align: center;
    padding: 2rem;
    font-size: 1.2rem;
    color: #6c757d;
  }
  
  .error {
    color: #dc3545;
  }
  
  .table {
    background-color: white;
  }
  
  .table thead th {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
  }
  
  .dual-listbox {
    display: flex;
    justify-content: space-between;
  }
  
  .dual-listbox .dual-listbox__container {
    width: 45%;
  }
  
  .dual-listbox .dual-listbox__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .dual-listbox .dual-listbox__button {
    margin: 5px 0;
  }`, "",{"version":3,"sources":["webpack://./src/css/admin-page.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,4CAA4C;IAC5C,sBAAsB;IACtB,qBAAqB;EACvB;;EAEA;IACE,wCAAwC;IACxC,uCAAuC;EACzC;;EAEA;IACE,yCAAyC;EAC3C;;EAEA;IACE,yBAAyB;IACzB,gCAAgC;EAClC;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;IACzB,gCAAgC;EAClC;;EAEA;IACE,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;EACzB;;EAEA;IACE,aAAa;EACf","sourcesContent":[".admin-page {\n    padding-top: 2rem;\n    padding-bottom: 2rem;\n  }\n  \n  .admin-header {\n    color: #333;\n    border-bottom: 2px solid rgba(89, 38, 16, 1);\n    padding-bottom: 0.5rem;\n    margin-bottom: 1.5rem;\n  }\n  \n  .card {\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    transition: box-shadow 0.3s ease-in-out;\n  }\n  \n  .card:hover {\n    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);\n  }\n  \n  .card-header {\n    background-color: #f8f9fa;\n    border-bottom: 1px solid #e9ecef;\n  }\n  \n  .loading, .error {\n    text-align: center;\n    padding: 2rem;\n    font-size: 1.2rem;\n    color: #6c757d;\n  }\n  \n  .error {\n    color: #dc3545;\n  }\n  \n  .table {\n    background-color: white;\n  }\n  \n  .table thead th {\n    background-color: #f8f9fa;\n    border-bottom: 2px solid #dee2e6;\n  }\n  \n  .dual-listbox {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .dual-listbox .dual-listbox__container {\n    width: 45%;\n  }\n  \n  .dual-listbox .dual-listbox__buttons {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n  }\n  \n  .dual-listbox .dual-listbox__button {\n    margin: 5px 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
